.auth-screen{
  height: 100%;
  overflow: hidden;
  background-color: #fff;
}

.auth-screen .content{
  background-color: #fff;
  padding: 50px;
}

.auth-screen .logo{
  position: relative;
}

.auth-screen .logo h1{
  position: absolute;
  color: #2E368D;
  position: absolute;
  bottom: -28px;
  left: 145px;
}

.auth-screen .form{
  padding: 80px 40px;
}

.auth-screen .form h3{
  color: #43425D;
  margin-bottom: 30px;
}

.auth-screen .form input{
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius:0
}

.auth-screen .form .ant-form-item{
  margin: 0;
}

.auth-screen .form .submit{
  width: 200px;
  height: 50px;
  border-radius: 30px;
  font-size: 22px;
  background-color: #492C6E;
  color:#fff;
  margin: 35px 0;
}

.auth-screen .form .link{
  text-decoration: underline;
  color: #492C6E;
}


:root {
  --body-bg: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  --msger-bg: #fff;
  --border: 2px solid #ddd;
  --left-msg-bg: #ececec;
  --right-msg-bg: #3B80F7;
}

.details {
  width: 55%;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  min-height: 400;
}

.ant-layout{
  background: transparent
}
.ant-layout-content{
  margin:0 !important
}
.msger {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 95%;
  margin: 25px 10px;
  height: calc(100% - 50px);
  border: var(--border);
  border-radius: 5px;
  background: var(--msger-bg);
  box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
}

.msger-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: var(--border);
  background: #eee;
  color: #666;
}


.msger-inputarea {
  display: flex;
  padding: 10px;
  border-top: var(--border);
  background: #eee;
}
.msger-inputarea * {
  padding: 10px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
}
.msger-input {
  flex: 1;
}
.msger-send-btn {
  margin-left: 10px;
  background: #3B80F7;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.23s;
}
.msger-send-btn:hover {
  background: #215CC2;
}
.right-msg .msg-img {
  margin: 0 0 0 10px;
}

img.msg-img {
  width: 40px;
  height: 40px;
}

.msger-chat::-webkit-scrollbar {
  width: 6px;
}
.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}
.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}

.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;

}
.msg:last-of-type {
  margin: 0;
}
.msg-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}
.msg-bubble {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
  background: var(--left-msg-bg);
}
.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}
.msg-info-time {
  font-size: 0.85em;
}

.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}

.right-msg {
  flex-direction: row-reverse;
}
.right-msg .msg-bubble {
  background: var(--right-msg-bg);
  color: #fff;
  border-bottom-right-radius: 0;
}
.right-msg .msg-img {
  margin: 0 0 0 10px;
}

.optionsView{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom:10px
}

#select-parent {
  position: fixed;
  top: 70px;
  right: 10px;
  width: 400px;
}
.ant-select-selection--single {
  position: relative;
  height: 32px;
  cursor: pointer;
  left: 12px;
  margin: 20px 0 0px 0;
  width:95%
}
.ant-select-dropdown.ant-select-dropdown--single.ant-select-dropdown-placement-bottomLeft.slide-up-leave.slide-up-leave-active {
  width: 95%;
}

a {
  cursor: pointer;
}

.logout{
  display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 159px;
    margin: 20px 0 0px 0;
}

.logout .ant-btn-primary {
  color: #fff;
  background-color: #65686a;
  border-color: #65686a;
  width: 100%;
  font-weight: bold;
}

.login-form {
  max-width: 300px;
}

.login-form-button {
  width: 100%;
}